import React from 'react';
import {FooterText} from './style';

function Footer(){
    return(
        <section>        
          <FooterText>
             © ADVORA . All Rights Reserverd.
          </FooterText>
        </section>
      )
  }
  
export default Footer;
        
      