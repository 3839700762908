import React from 'react';
import {HeaderText} from './style';

function Header(){
    return(
     <section>
        <HeaderText>
         ADVORA
        </HeaderText>
     </section>
     )
}

export default Header;
            